<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Inventario</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Traspasos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h3 style="border-bottom: 1px solid #005f91">
        <strong>REGISTRAR TRASPASO DE PRODUCTOS</strong>
      </h3>
    </div>
    <div class="col-12">
      <div class="p-fluid formgrid grid p-fondo p-2">
        <div class="field col-12 md:col-3">
          <span><strong>PREPARADO POR:</strong></span>
          <Dropdown
            v-model="userSelected"
            :options="users"
            optionLabel="name"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>RECIBIDO POR:</strong></span>
          <Dropdown
            v-model="userRecibeSelected"
            :options="users"
            optionLabel="name"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>ORIGEN:</strong></span>
          <Dropdown
            v-model="sucursalSelected"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>DESTINO:</strong></span>
          <Dropdown
            v-model="sucursalDestinoSelected"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-6">
          <span><strong>BUSCAR PRODUCTO:</strong></span>
          <AutoComplete
            :dropdown="true"
            field="descripcion"
            v-model="productoSelected"
            :suggestions="productosListadoFiltrado"
            @complete="buscarProducto($event)"
            placeholder="Buscar Producto"
          >
            <!-- @item-select="agregarProductoDetalle" -->
            <template #item="slotProps">
              <div>
                {{ slotProps.item.descripcion }} <strong>**</strong>
                {{ slotProps.item.fabrica_nombre }} <strong>**</strong>
                <span
                  :class="
                    stockClass(
                      slotProps.item.stock.length > 0
                        ? slotProps.item.stock[0].cantidad
                        : 0
                    )
                  "
                  ><strong>{{
                    slotProps.item.stock.length > 0
                      ? slotProps.item.stock[0].cantidad
                      : 0
                  }}</strong></span
                >
              </div>
            </template>
          </AutoComplete>
          <small class="p-invalid" v-if="errors.productoSelected">{{
            errors.productoSelected[0]
          }}</small>
        </div>

        <div class="field col-12 md:col-4">
          <span><strong>OBSERVACIONES:</strong></span>
          <Textarea
            v-model="observaciones"
            :autoResize="true"
            :rows="1"
            placeholder="Observaciones"
          ></Textarea>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ACCIONES:</strong></span>
          <Button
            label="REALIZAR TRASPASO"
            icon="pi pi-save"
            class="p-button-info p-button-sm"
            v-tooltip.top="'Registrar Movimiento'"
            @click="guardarMovimiento"
            :disabled="sucursales.length <= 1"
          />
        </div>
      </div>
      <div class="mt-2" v-if="this.mostrarTablaFechasVencimiento == true">
        <DataTable
          ref="fechas_vencimiento"
          :value="fechas_vencimiento"
          class="p-datatable-sm mt-2"
          responsiveLayout="scroll"
          scrollHeight="400px"
          :rowhover="true"
          stripedRows
          showGridlines
          selectionMode="single click"
          :style="{ border: '1px solid #000000' }"
        >
          <template #empty> Debe seleccionar un producto </template>
          <Column field="producto" header="DESCRIPCIÓN/PRODUCTO">
            {{ data.producto ?? "" }}
          </Column>
          <Column class="text-center" field="stock_actual" header="EN STOCK">
            <template #body="{ data }">
              <div :class="stockClass(data.stock_actual)">
                {{ convertirNumeroGermanicFormat(data.stock_actual) }}
              </div>
            </template>
          </Column>
          <Column
            class="text-right"
            field="ultimo_precio"
            header="PRECIO"
            style="font-weight: bold; font-size: 1.2em"
          >
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.ultimo_precio ?? 0) }}
            </template>
          </Column>
          <Column class="text-center" field="fecha_vence" header="VENCIEMIENTO">
            <template
              #body="{ data }"
              style="font-weight: bold; font-size: 1.3em"
            >
              {{
                data.fecha_vence == "1905-03-14"
                  ? "Sin Fecha"
                  : formatDate(data.fecha_vence)
              }}
            </template>
          </Column>
          <Column class="text-center" field="lote" header="LOTE">
            {{ data.lote ?? "0" }}
          </Column>
          <Column header="ACCIONES" style="text-align: center">
            <template #body="slotProps">
              <Button
                icon="pi pi-plus"
                class="p-button-rounded p-button-info mr-1"
                @click="agregarProductoDetalle(slotProps.data)"
                v-tooltip.top="'Agregar al Detalle'"
                :disabled="slotProps.data.cantidad <= 0"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="mt-2">
        <DataTable
          ref="traspasos"
          key="id"
          :value="traspasos"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          responsive="true"
          editMode="cell"
          stripedRows
          showGridlines
          selectionMode="single click"
          @cell-edit-complete="guardandoCambiosCelda"
        >
          <template #loading
            ><div class="flex align-items-center justify-content-center">
              <ProgressSpinner /></div
          ></template>
          <template #empty>
            <div class="flex align-items-center justify-content-center">
              <div class="p-invalid">No existen Productos en el detalle!</div>
            </div>
          </template>
          <Column header="#" class="text-right" :style="{ width: '5%' }">
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template>
          </Column>
          <Column field="id" header="CÓDIGO" class="text-right"></Column>
          <Column field="fabrica_nombre" header="LABORATORIO"></Column>
          <Column
            field="producto"
            header="DETALLE/PRODUCTO"
            style="font-weight: bold"
          ></Column>
          <Column field="unidad_medida_nombre" header="U/M"></Column>
          <Column
            class="text-center bold"
            field="cantidad"
            header="STOCK"
            :sortable="true"
          >
            <template #body="{ data }">
              <div :class="claseEstado(data)">
                {{ data.stock_actual }}
              </div>
            </template>
          </Column>
          <Column
            class="text-center bold bg-green-200"
            field="cantidad"
            header="CANTIDAD"
          >
            <template #body="slotProps">
              {{ slotProps.data.cantidad }}
            </template>
            <template #editor="slotProps">
              <InputNumber
                :inputClass="'text-right'"
                :min="0"
                :minFractionDigits="0"
                locale="de-DE"
                v-model="slotProps.data.cantidad"
              />
            </template>
          </Column>
          <Column
            field="precio_compra"
            header="PUCompra"
            class="text-right"
            v-show="false"
          >
            {{ data.precio_compra }}
          </Column>
          <Column
            class="text-right strong"
            field="ultimo_precio"
            header="PUVenta"
            v-show="false"
          >
            <template #body="slotProps">
              {{ convertirNumeroGermanicFormat(slotProps.data.ultimo_precio) }}
            </template>
            <template #editor="{ data, field }">
              <InputNumber
                v-model="data[field]"
                input-class="flex-row-reverse"
                locale="de-DE"
                class="p-inputtext-sm"
                :min="1"
                :minFractionDigits="2"
                :maxFractionDigits="2"
              />
            </template>
          </Column>
          <Column header="ACCIONES">
            <template #body="slotProps">
              <Button
                @click="quitarProductoDetalle(slotProps)"
                v-tooltip.top="'Quitar Producto'"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import SucursalService from "@/service/SucursalService";
import ProductService from "@/service/ProductService";
import MovimientoInventarioService from "@/service/MovimientoInventarioService";
import UserService from "@/service/UserService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      traspasos: [],
      sucursalSelected: 1,
      sucursalDestinoSelected: 2,
      sucursales: [],
      tipoMovimientos: [],
      tipoMovimientoSelected: 1,
      productoSelected: null,
      productosListadoFiltrado: [],
      fecha_movimiento: new Date(),
      enviando: false,
      errors: {},
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      claseEstado: (data) => {
        if (data.stock_actual <= 0) {
          return "outofstock";
        } else if (data.stock_actual <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      users: [],
      userSelected: null,
      observaciones: "",
      userRecibeSelected: null,
      mostrarTablaFechasVencimiento: false,
      fechas_vencimiento: [],
      stock_traspasos: [],
    };
  },
  sucursalService: null,
  productService: null,
  movimientoInventarioService: null,
  userService: null,
  auth: null,
  created() {
    this.sucursalService = new SucursalService();
    this.productService = new ProductService();
    this.movimientoInventarioService = new MovimientoInventarioService();
    this.userService = new UserService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarUsuarios();
  },
  methods: {
    cargarUsuarios() {
      this.userService.getUsersAll().then((data) => {
        this.users = data;
        //cargo el usuario logueado
        this.users.forEach((user) => {
          if (user.id == this.auth.user.id) {
            this.userSelected = user.id;
          }
        });
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    validarFormulario() {
      if (
        this.sucursalSelected == null ||
        this.sucursalDestinoSelected == null
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar una sucursal",
          life: 3000,
        });
        return false;
      }

      if (this.sucursalDestinoSelected == this.sucursalSelected) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "La sucursal de origen y destino no pueden ser iguales",
          life: 3000,
        });
        return false;
      }
      if (this.userSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un usuario de entrega",
          life: 3000,
        });
        return false;
      }

      if (this.userRecibeSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un usuario de recepción",
          life: 3000,
        });
        return false;
      }

      if (this.traspasos.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe agregar al menos un producto al detalle",
          life: 3000,
        });
        return false;
      }
      return true;
    },
    guardarMovimiento() {
      if (this.validarFormulario()) {
        this.enviando = true;
        let datos = {
          sucursal_id: this.sucursalSelected,
          sucursal_destino_id: this.sucursalDestinoSelected,
          productos: this.traspasos,
          user_id: this.userSelected,
          user_recibe_id: this.userRecibeSelected,
          observaciones: this.observaciones,
        };
        this.$confirm.require({
          header: "Confirmación",
          message: "¿ESTÁS SEGURO DE GUARDAR EL MOVIMIENTO?",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Si, Guardar",
          rejectLabel: "No, Cancelar",
          acceptClass: "p-button-primary p-button-lg",
          rejectClass: "p-button-danger p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          discard: () => {
            this.guardando = false;
          },
          accept: () => {
            this.movimientoInventarioService
              .nuevoMovimiento(datos)
              .then((data) => {
                if (data.status == 400) {
                  this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: data.message,
                    life: 3000,
                  });
                  this.enviando = false;
                  return;
                } else {
                  this.$toast.add({
                    severity: "success",
                    summary: "Éxito",
                    detail: data.message,
                    life: 3000,
                  });
                  this.enviando = false;
                  this.traspasos = [];
                  this.observaciones = "";
                  this.userRecibeSelected = null;
                  /* this.imprimirMovimiento(mov_almacen.id); */
                }
              });
          },
          reject: () => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "No se guardo el movimiento",
              life: 3000,
            });
            this.enviando = false;
          },
        });
      }
    },
    /*  imprimirMovimiento(id) {
      this.nuevoMovimiento
        .imprimirMov(id)
        .then(() => {})
        .catch((error) => console.log(error));
    }, */
    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;
      //valido de la cantidad no sea mayor a la existencia
      if (field == "cantidad") {
        if (newValue > data.stock_actual) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "La cantidad no puede ser mayor al stock actual",
            life: 3000,
          });
          data.cantidad = parseFloat(data.stock_actual);
        }
      }
    },
    /* validacionesAntesAgregar() {
      let existe = this.traspasos.find((item) => {
        return item.id == this.productoSelected.id;
      });

      if (existe) {
        this.errors.productoSelect = ["El producto ya esta en el detalle"];
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya esta en el detalle",
          life: 3000,
        });
        this.enviando = false;
        return false;
      }

      if (this.cantidad <= 0 || this.cantidad === null) {
        this.errors.cantidad = ["La cantidad debe ser mayor a 0"];
        return false;
      }

      if (this.cantidad > this.productoSelected.stock[0].cantidad) {
        this.errors.cantidad = [
          "La cantidad no puede ser mayor al stock actual",
        ];
        return false;
      }
      return true;
    }, */
    formatDate(fecha) {
      if (fecha) {
        return fecha.split("-").reverse().join("/");
      }
      return "";
    },
    sinDecimal(numero) {
      return parseInt(numero);
    },
    agregarProductoDetalle(datos) {
      let existe = this.traspasos.find((item) => {
        return item.id == datos.id && item.fecha_vence == datos.fecha_vence;
      });

      if (existe) {
        this.errors.productoSelect = ["El producto ya esta en el detalle"];
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya esta en el detalle",
          life: 3000,
        });
        this.enviando = false;
        return false;
      }

      if (datos.stock_actual <= 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto no tiene stock",
          life: 3000,
        });
        this.enviando = false;
        return false;
      }

      //console.log(datos);
      this.traspasos.push({
        ...datos,
        id: datos.id,
        producto: datos.producto,
        stock_actual: this.sinDecimal(datos.stock_actual),
        precio_venta: parseFloat(datos.ultimo_precio),
        precio_compra: parseFloat(datos.precio_compra),
        cantidad: 1,
        fabrica_nombre: datos.fabrica_nombre,
        unidad_medida_nombre: datos.unidad_medida_nombre,
      });
      this.mostrarTablaFechasVencimiento = false;
      this.productoSelected = null;
      this.fechas_vencimiento = [];
      this.stock_traspasos = [];

      /*  let precio_venta =
        this.productoSelected.stock != null &&
        this.productoSelected.stock.length > 0
          ? this.productoSelected.stock[0].ultimo_precio
          : this.productoSelected.precio_sugerido;

      if (this.productoSelected.fechas_vencimiento.length > 1) {
        this.mostrarTablaFechasVencimiento = true;
        this.productoSelected.fechas_vencimiento.forEach((item) => {
          this.fechas_vencimiento.push({
            ...this.productoSelected,
            id: this.productoSelected.id,
            fabrica_nombre: this.productoSelected.fabrica_nombre,
            cantidad: item.cantidad,
            producto: this.productoSelected.descripcion,
            ultimo_precio: precio_venta,
            fecha_vence: item.fecha_vence,
            lote: item.lote,
          });
        });
      } else { */
      /* this.mostrarTablaFechasVencimiento = false;
      let producto = this.productoSelected;
      let cantidad = 1;
      let detalle = {
        id: producto.id,
        producto: producto.descripcion,
        stock_actual: parseFloat(producto.stock[0].cantidad),
        cantidad: parseFloat(cantidad),
        precio_compra: parseFloat(producto.stock[0].cpp) || 0,
        precio_venta: parseFloat(producto.stock[0].ultimo_precio) || 0,
        margen:
          parseFloat(producto.stock[0].ultimo_precio) -
          parseFloat(producto.precio_compra),
        margen_porcentaje:
          (parseFloat(producto.stock[0].ultimo_precio) /
            parseFloat(producto.precio_compra)) *
          100,
        redondeo:
          parseFloat(producto.precio_compra) +
          parseFloat(producto.stock[0].ultimo_precio) -
          parseFloat(producto.precio_compra),
        fabrica_nombre: producto.fabrica_nombre,
        proveedor_nombre: producto.fabricas.proveedor.nombre_completo,
        unidad_medida_nombre: producto.unidad_medida_nombre,
      };

      this.traspasos.push(detalle);
      this.productoSelected = null; */
      /* } */
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({
            texto: event.query,
            sucursal_id: this.sucursalSelected,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    quitarProductoDetalle(datos) {
      this.$confirm.require({
        header: "Confirmación",
        message: "¿ESTÁS SEGURO DE QUITAR EL PRODUCTO DEL DETALLE?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Quitar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        discard: () => {
          this.guardando = false;
        },
        accept: () => {
          this.traspasos.splice(datos.index, 1);
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Producto quitado del detalle",
            life: 3000,
          });
        },
        reject: () => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "No se quito el producto del detalle",
            life: 3000,
          });
        },
      });
    },
  },
  watch: {
    productoSelected(newVal) {
      if (newVal != null && newVal.id) {
        this.fechas_vencimiento = [];
        this.stock_traspasos = [];

        let precio_venta =
          newVal.stock.length > 0
            ? newVal.stock[0].precio_venta
            : newVal.precio_sugerido;

        let cpp = newVal.stock.length > 0 ? newVal.stock[0].cpp : newVal.cpp;

        if (newVal.fechas_vencimiento.length > 1) {
          this.mostrarTablaFechasVencimiento = true;
          newVal.fechas_vencimiento.forEach((item) => {
            this.fechas_vencimiento.push({
              id: newVal.id,
              fabrica_nombre: newVal.fabrica_nombre,
              stock_actual: item.cantidad,
              producto: newVal.descripcion,
              ultimo_precio: precio_venta,
              precio_compra: cpp,
              fecha_vence: item.fecha_vence,
              lote: item.lote,
              margen: parseFloat(precio_venta) - parseFloat(cpp),
              margen_porcentaje:
                (parseFloat(precio_venta) / parseFloat(cpp)) * 100,
              redondeo:
                parseFloat(cpp) + parseFloat(precio_venta) - parseFloat(cpp),
            });
          });
        } else {
          this.mostrarTablaFechasVencimiento = false;
          newVal.stock.forEach((producto) => {
            this.stock_traspasos.push({
              id: newVal.id,
              fabrica_nombre: newVal.fabrica_nombre,
              stock_actual: producto.cantidad,
              producto: newVal.descripcion,
              ultimo_precio: precio_venta,
              precio_compra: cpp,
              fecha_vence: producto.fecha_vence,
              lote: producto.lote,
              margen: parseFloat(precio_venta) - parseFloat(cpp),
              margen_porcentaje:
                (parseFloat(precio_venta) / parseFloat(cpp)) * 100,
              redondeo:
                parseFloat(cpp) + parseFloat(precio_venta) - parseFloat(cpp),
            });
            this.agregarProductoDetalle(this.stock_traspasos[0]);
          });
        }
      }
    },
    sucursalSelected() {
      //recorro las sucursales para obtener el id de la sucursal destino
      this.sucursales.forEach((sucursal) => {
        if (sucursal.id != this.sucursalSelected) {
          this.sucursalDestinoSelected = sucursal.id;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 10px;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
